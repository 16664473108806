import React, { Suspense, useEffect } from 'react';

import * as Sentry from '@sentry/react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import PrivateRoute from '../PrivateRoute/PrivateRoute';
import { RouterListener, ScrollToTop, InactivityLogoutTimer } from 'components/tools';
import { Container, Layout } from 'components/ui/general';
import { ConfirmationModal } from 'components/ui/modals';
import ActAsRetailerModal from 'components/ui/modals/ActAsRetailerModal';
import { setUser, setActAsRetailerRight } from 'redux/auth';
import routes from 'routes';
import NotFound from 'routes/NotFound/NotFound';
import { UserRole, useUserByTokenQuery, User } from 'types';

const App = () => {
  const { data: { userByToken } = {} } = useUserByTokenQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userByToken && userByToken.user) {
      const user = (userByToken.user as unknown) as User;
      dispatch(setUser({ user }));

      if (
        userByToken.actAsRetailerRight != null ||
        userByToken.actAsRetailerRight !== undefined
      ) {
        dispatch(
          setActAsRetailerRight({
            actAsRetailerRight: userByToken.actAsRetailerRight as boolean
          })
        );
      }
    }
  }, [dispatch, userByToken]);

  return (
    <>
      <Helmet titleTemplate="%s | Dealy" defaultTitle="Dealy" />
      <InactivityLogoutTimer />
      <Router>
        <RouterListener />
        <ScrollToTop />
        <Layout>
          <Sentry.ErrorBoundary fallback={<NotFound />}>
            <Suspense fallback={<Container>Loading...</Container>}>
              <Switch>
                <Route exact path="/" component={routes.Login} />
                <Route exact path="/forgot" component={routes.Forgot} />
                <Route
                  exact
                  path="/reset/:email/:nonce"
                  component={routes.Reset}
                />
                <PrivateRoute exact path="/portal" component={routes.Portal} />
                <PrivateRoute
                  exact
                  path="/users"
                  component={routes.Users}
                  allowedRoles={[
                    UserRole.SuperAdmin,
                    UserRole.DealyAdmin,
                    UserRole.RetailerAdmin
                  ]}
                />
                <PrivateRoute
                  exact
                  path="/maintenance-tasks"
                  component={routes.MaintenanceTasks}
                  allowedRoles={[
                    UserRole.SuperAdmin,
                    UserRole.DealyAdmin,
                    UserRole.RetailerAdmin,
                    UserRole.RetailerWorkshop
                  ]}
                />
                <PrivateRoute
                  exact
                  path="/maintenance-task/:id"
                  component={routes.MaintenanceTask}
                  allowedRoles={[
                    UserRole.SuperAdmin,
                    UserRole.DealyAdmin,
                    UserRole.RetailerAdmin,
                    UserRole.RetailerWorkshop
                  ]}
                />
                <PrivateRoute
                  exact
                  path="/new-maintenance-task"
                  component={routes.CreateMaintenanceTask}
                  allowedRoles={[
                    UserRole.SuperAdmin,
                    UserRole.DealyAdmin,
                    UserRole.RetailerAdmin,
                    UserRole.RetailerWorkshop
                  ]}
                />
                <PrivateRoute
                  exact
                  path="/new-maintenance-task/:id"
                  component={routes.CreateMaintenanceTask}
                  allowedRoles={[
                    UserRole.SuperAdmin,
                    UserRole.DealyAdmin,
                    UserRole.RetailerAdmin,
                    UserRole.RetailerWorkshop
                  ]}
                />
                <PrivateRoute
                  exact
                  path="/maintenance-tasks/tender/:id"
                  component={routes.MaintenanceTaskTender}
                  allowedRoles={[
                    UserRole.SuperAdmin,
                    UserRole.DealyAdmin,
                    UserRole.RetailerAdmin,
                    UserRole.RetailerWorkshop
                  ]}
                />
                <PrivateRoute
                  exact
                  path="/custom-prices"
                  component={routes.CustomPrices}
                  allowedRoles={[UserRole.SuperAdmin, UserRole.DealyAdmin]}
                />
                <PrivateRoute
                  exact
                  path="/settings"
                  component={routes.Settings}
                />
                <PrivateRoute
                  exact
                  path="/terms-and-conditions"
                  component={routes.TermsAndConditions}
                />
                <PrivateRoute
                  exact
                  path="/vehicles"
                  component={routes.Vehicles}
                />
                <PrivateRoute
                  exact
                  path="/showroom"
                  component={routes.Showroom}
                  allowedRoles={[
                    UserRole.SuperAdmin,
                    UserRole.DealyAdmin,
                    UserRole.RetailerAdmin,
                    UserRole.RetailerUser,
                    UserRole.RetailerSeller
                  ]}
                />
                <PrivateRoute
                  exact
                  path="/consignment"
                  component={routes.Consignment}
                  allowedRoles={[
                    UserRole.SuperAdmin,
                    UserRole.DealyAdmin,
                    UserRole.RetailerAdmin,
                    UserRole.RetailerUser,
                    UserRole.RetailerSeller
                  ]}
                />
                <PrivateRoute
                  exact
                  path="/retailer"
                  component={routes.Retailer}
                  allowedRoles={[UserRole.SuperAdmin, UserRole.DealyAdmin]}
                />
                <PrivateRoute
                  exact
                  path="/operation-groups"
                  component={routes.OperationGroups}
                  allowedRoles={[UserRole.SuperAdmin, UserRole.DealyAdmin]}
                />
                <PrivateRoute
                  exact
                  path="/operation-groups/:id"
                  component={routes.OperationGroup}
                  allowedRoles={[UserRole.SuperAdmin, UserRole.DealyAdmin]}
                />
                <PrivateRoute
                  exact
                  path="/warranties"
                  component={routes.Warranties}
                  allowedRoles={[
                    UserRole.SuperAdmin,
                    UserRole.DealyAdmin,
                    UserRole.RetailerAdmin,
                    UserRole.RetailerUser,
                    UserRole.RetailerWorkshop
                  ]}
                />
                <PrivateRoute
                  exact
                  path="/warranty/:id"
                  component={routes.Warranty}
                  allowedRoles={[
                    UserRole.SuperAdmin,
                    UserRole.DealyAdmin,
                    UserRole.RetailerAdmin,
                    UserRole.RetailerUser,
                    UserRole.RetailerWorkshop
                  ]}
                />
                <PrivateRoute
                  exact
                  path="/new-warranty"
                  component={routes.CreateWarranty}
                  allowedRoles={[
                    UserRole.RetailerAdmin,
                    UserRole.RetailerUser,
                    UserRole.RetailerWorkshop
                  ]}
                />
                <PrivateRoute
                  exact
                  path="/new-warranty/:id"
                  component={routes.CreateWarranty}
                  allowedRoles={[
                    UserRole.RetailerAdmin,
                    UserRole.RetailerUser,
                    UserRole.RetailerWorkshop
                  ]}
                />
                <PrivateRoute
                  exact
                  path="/exploded-views/file/:id"
                  component={routes.ExplodedView}
                  allowedRoles={[UserRole.SuperAdmin, UserRole.DealyAdmin]}
                />

                <PrivateRoute
                  exact
                  path="/exploded-views/folder/:id"
                  component={routes.ExplodedViews}
                  allowedRoles={[UserRole.SuperAdmin, UserRole.DealyAdmin]}
                />
                <PrivateRoute
                  exact
                  path="/exploded-views"
                  component={routes.ExplodedViews}
                  allowedRoles={[UserRole.SuperAdmin, UserRole.DealyAdmin]}
                />
                <PrivateRoute
                  exact
                  path="/my-orders"
                  component={routes.MyOrders}
                />
                <PrivateRoute
                  exact
                  path="/my-orders/:id"
                  component={routes.MyOrder}
                />
                <PrivateRoute
                  exact
                  path="/my-invoices"
                  component={routes.MyInvoices}
                />
                <PrivateRoute
                  exact
                  path="/my-invoices/:invoiceNumber"
                  component={routes.MyInvoice}
                />
                <PrivateRoute
                  exact
                  path="/spare-parts"
                  component={routes.SparePartsGuide}
                />
                <PrivateRoute
                  exact
                  path="/spare-parts/list"
                  component={routes.SparePartsList}
                />
                <PrivateRoute
                  exact
                  path="/webshop"
                  component={routes.Webshop}
                />

                <PrivateRoute
                  exact
                  path="/webshop/cart"
                  component={routes.WebshopCart}
                />
                <PrivateRoute
                  exact
                  path="/webshop/checkout"
                  component={routes.WebshopCheckout}
                />
                <PrivateRoute
                  exact
                  path="/webshop/checkout_confirm/"
                  component={routes.WebshopCheckoutConfirm}
                />
                <PrivateRoute
                  exact
                  path="/webshop/exploded/:id?"
                  component={routes.SparePart}
                />
                <PrivateRoute
                  exact
                  path="/webshop/search"
                  component={routes.WebshopSearch}
                />
                <PrivateRoute
                  exact
                  path="/webshop/:section/:id?"
                  component={routes.Webshop}
                />
                <PrivateRoute
                  exact
                  path="/webshop/:section"
                  component={routes.Webshop}
                />
                <PrivateRoute
                  exact
                  path="/admin/product-rules"
                  component={routes.ProductRules}
                />
                <PrivateRoute
                  exact
                  path="/admin/product-rules/:id?"
                  component={routes.ProductRuleDetail}
                />
                <PrivateRoute
                  exact
                  path="/admin/volume-discounts"
                  component={routes.VolumeDiscounts}
                />

                <Route component={routes.NotFound} />
              </Switch>
            </Suspense>
            <ConfirmationModal />
            <ActAsRetailerModal />
          </Sentry.ErrorBoundary>
        </Layout>
      </Router>
    </>
  );
};

export default App;
